import React, { Suspense } from 'react';
// import PropTypes from 'prop-types';

// import { Loading, NotFoundPage } from 'bumblebee-ui';
import { Loading } from 'murder-hornet-ui';
import { createBrowserHistory as createHistory } from 'history';
import { Route, Routes } from 'react-router-dom';

import {
  AppWrapper,
  CustomRouter,
  lazyWithRetry,
  PrivateRoute,
  PublicRoute,
  ScrollToTop,
} from './helpers';

// LANDING
const HomePage = lazyWithRetry(() => import('../components/HomePage'));

// AUTH
const LogIn = lazyWithRetry(() => import('../components/auth/LogIn'));
const Register = lazyWithRetry(() => import('../components/auth/Register'));
const ValidateMFA = lazyWithRetry(() => import('../components/auth/ValidateMFA'));
const ForgotPassword = lazyWithRetry(() => import('../components/auth/ForgotPassword'));
const ResetPassword = lazyWithRetry(() => import('../components/auth/ResetPassword'));

// DASHBOARD
const Dashboard = lazyWithRetry(() => import('../components/Dashboard'));

// APPLICATION PIPELINE
const AllApplications = lazyWithRetry(() => import('../components/application-pipeline/AllApplications'));
const ViewApplication = lazyWithRetry(() => import('../components/application-pipeline/ViewApplication'));

// MY SETTINGS
const IndexMySettings = lazyWithRetry(() => import('../components/settings/my-settings/IndexMySettings'));
const PersonalInformation = lazyWithRetry(() => import('../components/settings/my-settings/PersonalInformation'));
const ResetPasswordLoggedIn = lazyWithRetry(() => import('../components/settings/my-settings/ResetPassword'));
const Sessions = lazyWithRetry(() => import('../components/settings/my-settings/Sessions'));
const Notifications = lazyWithRetry(() => import('../components/settings/my-settings/Notifications'));

// SYSTEM SETTINGS
const IndexSystemSettings = lazyWithRetry(() => import('../components/settings/system-settings/IndexSystemSettings'));
const OrganizationDetails = lazyWithRetry(() => import('../components/settings/system-settings/OrganizationDetails'));
const Theme = lazyWithRetry(() => import('../components/settings/system-settings/Theme'));
const LockDesk = lazyWithRetry(() => import('../components/settings/system-settings/lock-desk/LockDesk'));
const RateSheets = lazyWithRetry(() => import('../components/settings/system-settings/rate-sheets/RateSheets'));
const RateSheetDetail = lazyWithRetry(() => import('../components/settings/system-settings/rate-sheets/RateSheetDetail'));
const Flags = lazyWithRetry(() => import('../components/settings/system-settings/flags/Flags'));
const ActivityLogTemplates = lazyWithRetry(() => import('../components/settings/system-settings/templates/ActivityLogTemplates'));
const MessageCenterTemplates = lazyWithRetry(() => import('../components/settings/system-settings/templates/MessageCenterTemplates'));
const DocumentNoteTemplates = lazyWithRetry(() => import('../components/settings/system-settings/templates/DocumentNoteTemplates'));
const Statuses = lazyWithRetry(() => import('../components/settings/system-settings/Statuses'));
const CreateUpdateStatus = lazyWithRetry(() => import('../components/settings/system-settings/CreateUpdateStatus'));
const IndexFormData = lazyWithRetry(() => import('../components/settings/system-settings/form-data/IndexFormData'));
const CensusTracts = lazyWithRetry(() => import('../components/settings/system-settings/form-data/CensusTracts'));
const Counties = lazyWithRetry(() => import('../components/settings/system-settings/form-data/Counties'));
const CreateUpdateCounty = lazyWithRetry(() => import('../components/settings/system-settings/form-data/CreateUpdateCounty'));
const UserDenialReasons = lazyWithRetry(() => import('../components/settings/system-settings/form-data/UserDenialReasons'));
const UserCancelReasons = lazyWithRetry(() => import('../components/settings/system-settings/form-data/UserCancelReasons'));
const ZipCodes = lazyWithRetry(() => import('../components/settings/system-settings/form-data/ZipCodes'));
const Milestones = lazyWithRetry(() => import('../components/settings/system-settings/milestones/Milestones'));
const DocumentCategories = lazyWithRetry(() => import('../components/settings/system-settings/document-categories/DocumentCategories'));
const ConditionTemplates = lazyWithRetry(() => import('../components/settings/system-settings/conditions/ConditionTemplates'));
const CreateUpdateConditionTemplate = lazyWithRetry(() => import('../components/settings/system-settings/conditions/CreateUpdateConditionTemplate'));

// FUNDING SOURCES
const IndexFundingSources = lazyWithRetry(() => import('../components/settings/funding-sources/IndexFundingSources'));
const ViewFundingSource = lazyWithRetry(() => import('../components/settings/funding-sources/ViewFundingSource'));
const FundingSourceDetails = lazyWithRetry(() => import('../components/settings/funding-sources/FundingSourceDetails'));
const FundingSourcePrograms = lazyWithRetry(() => import('../components/settings/funding-sources/FundingSourcePrograms'));
const LocalLockDesk = lazyWithRetry(() => import('../components/settings/funding-sources/LocalLockDesk'));
const FinancialLedger = lazyWithRetry(() => import('../components/settings/funding-sources/FinancialLedger'));

// PROGRAMS
const IndexPrograms = lazyWithRetry(() => import('../components/settings/programs/IndexPrograms'));
const ViewProgram = lazyWithRetry(() => import('../components/settings/programs/ViewProgram'));
const ProgramDetails = lazyWithRetry(() => import('../components/settings/programs/ProgramDetails'));
const AssociatedPrograms = lazyWithRetry(() => import('../components/settings/programs/AssociatedPrograms'));
const InterestRates = lazyWithRetry(() => import('../components/settings/programs/InterestRates'));
const ProgramFundingSources = lazyWithRetry(() => import('../components/settings/programs/ProgramFundingSources'));
const PricingTiers = lazyWithRetry(() => import('../components/settings/programs/PricingTiers'));
const FeeSchedule = lazyWithRetry(() => import('../components/settings/programs/FeeSchedule'));
const LenderAccess = lazyWithRetry(() => import('../components/settings/programs/LenderAccess'));
const Checklists = lazyWithRetry(() => import('../components/settings/programs/Checklists'));
const ChecklistBuilder = lazyWithRetry(() => import('../components/settings/programs/forms/ChecklistBuilder'));

// LENDERS
const IndexLenders = lazyWithRetry(() => import('../components/settings/lenders/IndexLenders'));
const ViewLender = lazyWithRetry(() => import('../components/settings/lenders/ViewLender'));
const LenderDetails = lazyWithRetry(() => import('../components/settings/lenders/LenderDetails'));
const LenderUsers = lazyWithRetry(() => import('../components/settings/lenders/LenderUsers'));
const ViewLenderUser = lazyWithRetry(() => import('../components/settings/lenders/ViewLenderUser'));
const InviteLenderUser = lazyWithRetry(() => import('../components/settings/lenders/InviteLenderUser'));
const LenderPermissionGroups = lazyWithRetry(() => import('../components/settings/lenders/LenderPermissionGroups'));

// USERS
const IndexUsers = lazyWithRetry(() => import('../components/settings/users/IndexUsers'));
const Teams = lazyWithRetry(() => import('../components/settings/users/Teams'));
const TeamUsers = lazyWithRetry(() => import('../components/settings/users/TeamUsers'));
const ViewTeamUser = lazyWithRetry(() => import('../components/settings/users/ViewTeamUser'));
const AllUsers = lazyWithRetry(() => import('../components/settings/users/AllUsers'));
const ViewUser = lazyWithRetry(() => import('../components/settings/users/ViewUser'));
const InviteUser = lazyWithRetry(() => import('../components/settings/users/InviteUser'));
const UserPermissionGroups = lazyWithRetry(() => import('../components/settings/users/UserPermissionGroups'));

// HELP
const Help = lazyWithRetry(() => import('../components/settings/help/Help'));
// RELEASE NOTES
const ReleaseNotes = lazyWithRetry(() => import('../components/settings/help/release-notes/ReleaseNotes'));
const ViewReleaseNote = lazyWithRetry(() => import('../components/settings/help/release-notes/ViewReleaseNote'));
// HELP TICKETS
// const HelpTickets = lazyWithRetry(() => import('../components/settings/help/help-tickets/HelpTickets'));
// const CreateHelpTicket = lazyWithRetry(() => import('../components/settings/help/help-tickets/CreateHelpTicket'));
// const ViewHelpTicket = lazyWithRetry(() => import('../components/settings/help/help-tickets/ViewHelpTicket'));
// const HelpTicketDetails = lazyWithRetry(() => import('../components/settings/help/help-tickets/tabs/HelpTicketDetails'));
// const HelpTicketCommunications = lazyWithRetry(() => import('../components/settings/help/help-tickets/tabs/HelpTicketCommunications'));

// TEST
const TestPage = lazyWithRetry(() => import('../components/TestPage'));

export const history = createHistory();

const AppRouter = () => (
  <CustomRouter history={history}>
    <ScrollToTop>
      <div className='container-scroller'>

        <AppWrapper>
          <Suspense fallback={<Loading main />}>
            <Routes>
              {/* LANDING */}
              <Route path='/' element={<PublicRoute><HomePage /></PublicRoute>} />

              {/* AUTH */}
              <Route path='/login/:organization_uuid' element={<PublicRoute><LogIn /></PublicRoute>} />
              <Route path='/register/:organization_uuid/:token_uuid' element={<PublicRoute><Register /></PublicRoute>} />
              <Route path='/validate/session/:organization_uuid' element={<PublicRoute><ValidateMFA /></PublicRoute>} />
              <Route path='/forgot-password/:organization_uuid' element={<PublicRoute><ForgotPassword /></PublicRoute>} />
              <Route path='/reset-password/:organization_uuid/:token_uuid' element={<PublicRoute><ResetPassword /></PublicRoute>} />

              {/* DASHBOARD */}
              <Route path='/dashboard' element={<PrivateRoute><Dashboard /></PrivateRoute>} />

              {/* APPLICATIONS */}
              <Route path='/applications' element={<PrivateRoute><AllApplications /></PrivateRoute>} />
              <Route path='/applications/:application_uuid/:layout?' element={<PrivateRoute><ViewApplication /></PrivateRoute>} />

              {/* MY SETTINGS */}
              <Route path='/my-settings'>
                <Route index element={<PrivateRoute><IndexMySettings /></PrivateRoute>} />
                <Route path='personal-information' element={<PrivateRoute><PersonalInformation /></PrivateRoute>} />
                <Route path='reset-password' element={<PrivateRoute><ResetPasswordLoggedIn /></PrivateRoute>} />
                <Route path='sessions' element={<PrivateRoute><Sessions /></PrivateRoute>} />
                <Route path='notifications' element={<PrivateRoute><Notifications /></PrivateRoute>} />
              </Route>

              {/* SYSTEM SETTINGS */}
              <Route path='system-settings'>
                <Route index element={<PrivateRoute perform={['processor:dummy:all']}><IndexSystemSettings /></PrivateRoute>} />
                <Route path='organization' element={<PrivateRoute perform={['processor:dummy:all']}><OrganizationDetails /></PrivateRoute>} />
                <Route path='theme' element={<PrivateRoute perform={['processor:dummy:all']}><Theme /></PrivateRoute>} />
                <Route path='lock-desk' element={<PrivateRoute perform={['processor:dummy:all']}><LockDesk /></PrivateRoute>} />
                <Route path='rate-sheets' element={<PrivateRoute perform={['processor:dummy:all']}><RateSheets /></PrivateRoute>} />
                <Route path='rate-sheets/:rate_sheet_uuid' element={<PrivateRoute perform={['processor:dummy:all']}><RateSheetDetail /></PrivateRoute>} />
                <Route path='statuses' element={<PrivateRoute perform={['processor:dummy:all']}><Statuses /></PrivateRoute>} />
                <Route path='statuses/create' element={<PrivateRoute perform={['processor:dummy:all']}><CreateUpdateStatus /></PrivateRoute>} />
                <Route path='statuses/edit/:processor_status_uuid' element={<PrivateRoute perform={['processor:dummy:all']}><CreateUpdateStatus /></PrivateRoute>} />
                <Route path='checklists' element={<PrivateRoute perform={['processor:dummy:all']}><Checklists /></PrivateRoute>} />
                <Route path='checklists/:checklist_uuid' element={<PrivateRoute perform={['processor:dummy:all']}><ChecklistBuilder /></PrivateRoute>} />
                <Route path='flags' element={<PrivateRoute perform={['processor:dummy:all']}><Flags /></PrivateRoute>} />
                <Route path='activity-log-templates' element={<PrivateRoute perform={['processor:dummy:all']}><ActivityLogTemplates /></PrivateRoute>} />
                <Route path='message-center-templates' element={<PrivateRoute perform={['processor:dummy:all']}><MessageCenterTemplates /></PrivateRoute>} />
                <Route path='document-note-templates' element={<PrivateRoute perform={['processor:dummy:all']}><DocumentNoteTemplates /></PrivateRoute>} />
                <Route path='form-data' element={<PrivateRoute perform={['processor:dummy:all']}><IndexFormData /></PrivateRoute>} />
                <Route path='form-data/census-tracts' element={<PrivateRoute perform={['processor:dummy:all']}><CensusTracts /></PrivateRoute>} />
                <Route path='form-data/counties' element={<PrivateRoute perform={['processor:dummy:all']}><Counties /></PrivateRoute>} />
                <Route path='form-data/counties/create' element={<PrivateRoute perform={['processor:dummy:all']}><CreateUpdateCounty /></PrivateRoute>} />
                <Route path='form-data/counties/edit/:county_uuid' element={<PrivateRoute perform={['processor:dummy:all']}><CreateUpdateCounty /></PrivateRoute>} />
                <Route path='form-data/user-denial-reasons' element={<PrivateRoute perform={['processor:dummy:all']}><UserDenialReasons /></PrivateRoute>} />
                <Route path='form-data/user-cancel-reasons' element={<PrivateRoute perform={['processor:dummy:all']}><UserCancelReasons /></PrivateRoute>} />
                <Route path='form-data/zip-codes' element={<PrivateRoute perform={['processor:dummy:all']}><ZipCodes /></PrivateRoute>} />
                <Route path='milestones' element={<PrivateRoute perform={['processor:dummy:all']}><Milestones /></PrivateRoute>} />
                <Route path='document-categories' element={<PrivateRoute perform={['processor:dummy:all']}><DocumentCategories /></PrivateRoute>} />
                <Route path='conditions' element={<PrivateRoute perform={['processor:dummy:all']}><ConditionTemplates /></PrivateRoute>} />
                <Route path='conditions/create' element={<PrivateRoute perform={['processor:dummy:all']}><CreateUpdateConditionTemplate /></PrivateRoute>} />
                <Route path='conditions/edit/:condition_template_uuid' element={<PrivateRoute perform={['processor:dummy:all']}><CreateUpdateConditionTemplate /></PrivateRoute>} />
              </Route>

              {/* FUNDING SOURCES */}
              <Route path='funding-sources'>
                <Route index element={<PrivateRoute perform={['processor:dummy:all']}><IndexFundingSources /></PrivateRoute>} />
                <Route path=':funding_source_uuid' element={<PrivateRoute perform={['processor:dummy:all']}><ViewFundingSource /></PrivateRoute>} />
                <Route path=':funding_source_uuid/funding-source-details' element={<PrivateRoute perform={['processor:dummy:all']}><FundingSourceDetails /></PrivateRoute>} />
                <Route path=':funding_source_uuid/programs' element={<PrivateRoute perform={['processor:dummy:all']}><FundingSourcePrograms /></PrivateRoute>} />
                <Route path=':funding_source_uuid/lock-desk' element={<PrivateRoute perform={['processor:dummy:all']}><LocalLockDesk /></PrivateRoute>} />
                <Route path=':funding_source_uuid/financial-ledger' element={<PrivateRoute perform={['processor:dummy:all']}><FinancialLedger /></PrivateRoute>} />
              </Route>

              {/* PROGRAMS */}
              <Route path='programs'>
                <Route index element={<PrivateRoute perform={['processor:dummy:all']}><IndexPrograms /></PrivateRoute>} />
                <Route path=':program_uuid' element={<PrivateRoute perform={['processor:dummy:all']}><ViewProgram /></PrivateRoute>} />
                <Route path=':program_uuid/program-details' element={<PrivateRoute perform={['processor:dummy:all']}><ProgramDetails /></PrivateRoute>} />
                <Route path=':program_uuid/associated-programs' element={<PrivateRoute perform={['processor:dummy:all']}><AssociatedPrograms /></PrivateRoute>} />
                <Route path=':program_uuid/rates' element={<PrivateRoute perform={['processor:dummy:all']}><InterestRates /></PrivateRoute>} />
                <Route path=':program_uuid/funding-sources' element={<PrivateRoute perform={['processor:dummy:all']}><ProgramFundingSources /></PrivateRoute>} />
                <Route path=':program_uuid/pricing-tiers' element={<PrivateRoute perform={['processor:dummy:all']}><PricingTiers /></PrivateRoute>} />
                <Route path=':program_uuid/fee-schedule' element={<PrivateRoute perform={['processor:dummy:all']}><FeeSchedule /></PrivateRoute>} />
                <Route path=':program_uuid/lender-access' element={<PrivateRoute perform={['processor:dummy:all']}><LenderAccess /></PrivateRoute>} />
              </Route>

              {/* LENDERS */}
              <Route path='lenders'>
                <Route index element={<PrivateRoute perform={['processor:dummy:all']}><IndexLenders /></PrivateRoute>} />
                <Route path=':lender_uuid' element={<PrivateRoute perform={['processor:dummy:all']}><ViewLender /></PrivateRoute>} />
                <Route path=':lender_uuid/lender-details' element={<PrivateRoute perform={['processor:dummy:all']}><LenderDetails /></PrivateRoute>} />
                <Route path=':lender_uuid/lender-users' element={<PrivateRoute perform={['processor:dummy:all']}><LenderUsers /></PrivateRoute>} />
                <Route path=':lender_uuid/lender-users/:lender_user_uuid' element={<PrivateRoute perform={['processor:dummy:all']}><ViewLenderUser /></PrivateRoute>} />
                <Route path=':lender_uuid/lender-users/invite' element={<PrivateRoute perform={['processor:dummy:all']}><InviteLenderUser /></PrivateRoute>} />
                <Route path=':lender_uuid/permission-groups' element={<PrivateRoute perform={['processor:dummy:all']}><LenderPermissionGroups /></PrivateRoute>} />
              </Route>

              {/* USERS */}
              <Route path='users'>
                <Route index element={<PrivateRoute perform={['processor:dummy:all']}><IndexUsers /></PrivateRoute>} />
                <Route path='teams' element={<PrivateRoute perform={['processor:dummy:all']}><Teams /></PrivateRoute>} />
                <Route path='teams/:team_uuid' element={<PrivateRoute perform={['processor:dummy:all']}><TeamUsers /></PrivateRoute>} />
                <Route path='teams/:team_uuid/:user_processor_uuid' element={<PrivateRoute perform={['processor:dummy:all']}><ViewTeamUser /></PrivateRoute>} />
                <Route path='all-users' element={<PrivateRoute perform={['processor:dummy:all']}><AllUsers /></PrivateRoute>} />
                <Route path='all-users/:user_processor_uuid' element={<PrivateRoute perform={['processor:dummy:all']}><ViewUser /></PrivateRoute>} />
                <Route path='invite-user' element={<PrivateRoute perform={['processor:dummy:all']}><InviteUser /></PrivateRoute>} />
                <Route path='user-permissions' element={<PrivateRoute perform={['processor:dummy:all']}><UserPermissionGroups /></PrivateRoute>} />
              </Route>

              {/* HELP */}
              <Route path='help'>
                <Route index element={<Help />} />

                {/* RELEASE NOTES */}
                <Route path='release-notes' element={<PrivateRoute><ReleaseNotes /></PrivateRoute>} />
                <Route path='release-notes/:release_note_uuid' element={<PrivateRoute><ViewReleaseNote /></PrivateRoute>} />

                {/* HELP TICKETS */}
                {/* <Route path='help-tickets' element={<PrivateRoute><HelpTickets /></PrivateRoute>} />
                <Route path='help-tickets/new' element={<PrivateRoute perform='processor:dummy:all'><CreateHelpTicket /></PrivateRoute>} />
                <Route path='help-tickets/:help_ticket_uuid' element={<PrivateRoute><ViewHelpTicket /></PrivateRoute>}>
                  <Route path='details' element={<HelpTicketDetails />} />
                  <Route path='communications' element={<HelpTicketCommunications />} />
                </Route> */}
              </Route>

              {/* TEST */}
              {environment !== 'production' && <Route path='/test' element={<TestPage />} />}

              {/* 503 Error Redirect */}
              <Route path='/unavailable' element={<div className='text-center mt-5'>Unavailable</div>} />

              <Route path='*' element={<div className='text-center mt-5'>Page Not Found</div>} />
            </Routes>
          </Suspense>
        </AppWrapper>

        {/* <Footer
            config={{ footer: config.footer, faqPage: config.faqPage, reportFraudPage: config.reportFraudPage }}
            version={VERSION}
            environment={environment}
            googleTranslate={<GoogleTranslate />} /> */}
      </div>
    </ScrollToTop>
  </CustomRouter>
);

AppRouter.propTypes = {
};

export default AppRouter;
